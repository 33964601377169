<template>
  <div>
    <LoginLogo></LoginLogo>
    <div class="login">
      <h1 class="login_title" style="text-decoration: underline">{{ textValue }}</h1>
    </div>
<!--     <el-divider style="margin-top: 0px;margin-bottom: 12px"></el-divider>-->
<!--    <div class="text" v-show="type ==='phone' || type === 'email'">-->
<!--      <span>{{ textValue }}</span>-->
<!--    </div>-->
    <div style="height: 360px;" v-show="type === 'address'">
      <GmapMap
          :center="{lat: addressLatValue, lng: addressLngValue}"
          :zoom="13"
          map-type-id="terrain"
          style="width: 100%; height: 100%"
      >
        <gmapMarker
            :position="{lat: addressLatValue, lng: addressLngValue}"
            :clickable="true"
            @mouseover="openInfoWindow()"
            @mouseout="closeInfoWindow()">
          <GmapInfoWindow
              :options="infoWindowOptions"
              :opened="infoWindowOptions.openFlg">
            <div @click="goMerchantDetail()">
              <img style="width: 200px;height: 100px" :src="infoWindowOptions.banner">
              <div class="map-merchant-name">{{infoWindowOptions.merchantName}}</div>
            </div>
          </GmapInfoWindow>
        </gmapMarker>
      </GmapMap>
    </div>
<!--     <el-divider></el-divider> -->
<!--    <div class="commitButton">-->
<!--      <van-button round block type="info" @click="commit()">{{ $t('app.calendarConfirmText') }}</van-button>-->
<!--    </div>-->
  </div>
</template>

<script>
import LoginLogo from "../../components/Login/Loginlogo.vue";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

let maplang = window.localStorage.getItem('language') || 'ja';
if (maplang == 'jp') {
  maplang = 'ja';
}
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAMvjJZ_HsByGEybbVbPNhsMHTJmnzaHe4',
    libraries: 'places',
    language: maplang,
  }
});

export default {
  props: [ 'title', 'text', 'merchantId', 'addressLat', 'addressLng', 'banner', 'merchantName'],
  components: {
    LoginLogo,
  },
  data() {
    return {
      titleText: '',
      type: '',
      textValue: '',
      merchantIdValue: '',
      addressLatValue: 10,
      addressLngValue: 10,
      infoWindowOptions : {
        maxWidth: 240,
        openFlg: false,
        banner: '',
        merchantName: '',
      }
    };
  },
  created() {
    this.type = this.title;
    this.getTitle(this.title);
    this.$watch(
      'title',
      (title) => {
        this.type = title;
        this.getTitle(title);
      }
    );
    this.textValue = this.text;
    this.$watch(
      'text',
      (text) => {
        this.textValue = text;
      }
    );
    this.merchantIdValue = this.merchantId;
    this.addressLatValue = parseFloat(this.addressLat);
    this.addressLngValue = parseFloat(this.addressLng);
    this.infoWindowOptions.banner = this.banner;
    this.infoWindowOptions.merchantName = this.merchantName;
  },
  methods: {
    getTitle(value) {
      value = value ? value : this.title
      switch (value) {
          case 'phone': this.titleText = this.$t('app.tel')
            break;
          case 'email': this.titleText = this.$t('app.mail')
            break;
          case 'address': this.titleText = this.$t('app.position')
            break;
        }
    },
    commit() {
      this.$emit('close')
    },
    openInfoWindow() {
      this.infoWindowOptions.openFlg = true;
    },
    closeInfoWindow() {
      this.infoWindowOptions.openFlg = false;
    },
  },
};
</script>

<style scoped lang="less">
.login {
  .login_title {
    text-align: left;
    margin: 30px 0;
    color: #000000;
  }
}
.text {
  span {
    font-size: .18rem;
    color: black;
  }
}
.commitButton {
  margin: 35px 0 0 0;
  .van-button {
    background-color: #000000;
    border: none;
    width: 322px;
    margin: 0 auto;
    height: 54px;
  }
}
</style>
